<template>
    <div class="work-switch">
        <button class="personal-btn">Personal</button>
        <button>Business</button>
    </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.work-switch {
    @apply flex justify-between items-center p-1;
    background: #18181b;
    height: 44px;
    border-radius: 4px;

    button {
        color: var(--Gray-500, #717680);

        height: 100%;
        width: 50%;
        /* Text sm/Semibold */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
    }

    .personal-btn {
        color: #fcb223;
        border-radius: 4px;
        background: #09090b;

        /* Shadows/shadow-sm */
        box-shadow: 0px 1px 3px 0px rgba(10, 13, 18, 0.1), 0px 1px 2px -1px rgba(10, 13, 18, 0.1);
    }
}
</style>
